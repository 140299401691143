import { Route, RouterProvider } from 'atomic-router-react'
import { lazy, Suspense } from 'react'
import 'shared/i18n'
import {
  analyticsRoute,
  courseRoute,
  coursesRoute,
  homeRoute,
  lessonRoute,
  privacyPolicyRoute,
  profileRoute,
} from 'shared/routes'
import { router } from './routing'

const Home = lazy(() => import('../2_pages/home/Home'))
const Courses = lazy(() => import('../2_pages/courses/Courses'))
const Course = lazy(() => import('../2_pages/course/Course'))
const Lesson = lazy(() => import('../2_pages/lesson/Lesson'))
const Profile = lazy(() => import('../2_pages/profile/Profile'))
const Analytics = lazy(() => import('../2_pages/analytics/Analytics'))
const PrivacyPolicy = lazy(
  () => import('../2_pages/privacyPolicy/PrivacyPolicy')
)
const MobileMenu = lazy(() => import('../3_widgets/mobileMenu/MobileMenu'))
const Modal = lazy(() => import('../6_shared/modal/Modal'))

export default function App() {
  return (
    <Suspense fallback={null}>
      <RouterProvider router={router}>
        <Route route={homeRoute} view={Home} />
        <Route route={coursesRoute} view={Courses} />
        <Route route={courseRoute} view={Course} />
        <Route route={lessonRoute} view={Lesson} />
        <Route route={profileRoute} view={Profile} />
        <Route route={analyticsRoute} view={Analytics} />
        <Route route={privacyPolicyRoute} view={PrivacyPolicy} />
        <MobileMenu />
        <Modal />
      </RouterProvider>
    </Suspense>
  )
}
